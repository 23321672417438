import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { titles } from '../../const/metaData';
import { Wrapper, TextContainer, BoldText } from '../../styles/commonStyles';

const Facebook: React.FunctionComponent = () => {
  return (
    <Layout>
      <SEO title={titles.socialMedia} />
      <Wrapper>
        <TextContainer>
          <h2>Bó Social Media House Rules</h2>
          <BoldText>House rules</BoldText>
          <p>
            You can use our social media accounts to ask questions and to share
            your thoughts about our services.
          </p>
          <p>
            We don’t want to stop anyone from having fun, but we also want our
            accounts to be a safe and respectful environment for everyone.
          </p>
          <p>That’s why we’ve created some rules around interacting with us.</p>
          <p>We’ve explained any legal terms in a clear way.</p>
          <p>Expectations of behaviour</p>
          <BoldText>We encourage you to:</BoldText>
          <ul>
            <li>Ask for help with queries about your bank accounts with us.</li>
            <li>Give us feedback on our products or services.</li>
            <li>
              Tell us when something’s wrong so that we can try to fix it.
            </li>
            <li>Only post information you’re happy for everyone to see.</li>
            <li>Share our posts with your friends or family.</li>
          </ul>
          <BoldText>But please don't:</BoldText>
          <ul>
            <li>
              Post personal or confidential information (for example account
              details). If you need to share this information with us, please do
              so in a private message.
            </li>
            <li>Be offensive, indecent or abusive.</li>
            <li>
              Post anything that could be considered defamatory or embarrassing,
              or damaging to someone’s reputation.
            </li>
            <li>
              Use our social media accounts in a way that might breach any laws
              and regulations including competition law.
            </li>
            <li>
              Direct people to sites containing viruses or anything that might
              damage their computer.
            </li>
            <li>
              Try to access the administrator features or functions of our
              social media accounts without permission.
            </li>
            <li>
              Spam or troll our social media accounts. How we manage our social
              media accounts
            </li>
            <li>
              Unless we say otherwise, the information we post on our social
              media accounts is only intended for residents of the United
              Kingdom.
            </li>
            <li>
              Our social media accounts may be managed by our employees and/or
              third parties appointed by us.
            </li>
            <li>
              If you find content on our social media accounts which you think
              is inappropriate or you’re concerned about, please contact us.
            </li>
            <li>
              Remember to also follow the social media platform’s (for example
              Facebook’s or Twitter’s) own rules and terms. If you don’t, they
              may hide your post or take other action and we don’t have control
              over this.
            </li>
          </ul>
          <p>
            We can’t moderate what others post before it is posted, but we may
            (if we’re able) remove posts which breach our House Rules. We may
            also block users who repeatedly break them.
          </p>
          <BoldText>Views and opinions</BoldText>
          <ul>
            <li>
              The comments and messages posted by others on our social media
              accounts are the views and opinions of the person posting and are
              not our views.
            </li>
            <li>
              Our social media accounts are hosted by independent providers
              (like Facebook, Twitter, YouTube and LinkedIn) and we can’t
              control what they do. Having an account doesn’t mean that we
              endorse them.
            </li>
          </ul>
          <BoldText>Using your comments, ideas and images</BoldText>
          <p>
            If we’d like to use anything you post or message to our social media
            accounts, we’ll ask you first.
          </p>
          <BoldText>Using our materials, content and images</BoldText>
          <p>
            We encourage you to share our social media content with others
            provided we’re identified as the source (for example, retweeting a
            tweet posted by us).
          </p>
          <p>
            However if creating your own online or social media content about
            any of our brands, please remember that our names and logos are
            protected by trademarks, and you need to get permission from us
            before using them.
          </p>
          <BoldText>Advice</BoldText>
          <p>
            The comments or messages we post on our social media accounts aren’t
            intended to give financial or investment advice and we recommend you
            get advice from a qualified financial advisor before making an
            investment decision.
          </p>
          <BoldText>Changes to our House Rules</BoldText>
          <ul>
            <li>
              We may change these House Rules from time to time. We’ll let you
              know on our social media accounts when we do this.
            </li>
            <li>
              Our social media accounts may sometimes be changed or temporarily
              unavailable, for example while we’re making improvements to them.
            </li>
          </ul>
          <p>
            We may also close our social media accounts without giving prior
            notice.
          </p>
          <BoldText>Your privacy</BoldText>
          <p>
            We’re committed to protecting any information we collect about you.
          </p>
          <p>
            We may record and keep information (anonymous or otherwise) about
            how you use our social media accounts (for example, the frequency of
            your visits and the comments you make).
          </p>
          <p>
            We may also share this information with other NatWest companies
            and/or with third parties acting on our behalf.
          </p>
          <p>Doing so helps us better understand and serve our customers.</p>
          <BoldText>Regulatory information</BoldText>
          <i>
            Bó is part of National Westminster Bank Plc (‘NatWest’).  Registered
            in England and Wales No. 929027.  Registered Office 250 Bishopsgate,
            London EC2M 4AA.  NatWest is authorised by the Prudential Regulation
            Authority and regulated by the Financial Conduct Authority and the
            Prudential Regulation Authority.  NatWest is entered on the
            Financial Services Register under registration number 121878.
          </i>
          <br />
          <br />
          <i>
            NatWest may monitor emails for business and operational purposes.
          </i>
        </TextContainer>
      </Wrapper>
    </Layout>
  );
};

export default Facebook;
